import React, { useState, useEffect } from 'react';

function Feature1Step1({ formData, onChange }) {
    const [teamTotals, setTeamTotals] = useState({ yourTeam: 0, yourOpponent: 0 });

    useEffect(() => {
        const calculateTeamTotal = (teamData) => {
            return teamData.reduce((sum, item, index) => sum + Number(item.skillLevel || 0) * Number(item.quantity || 0), 0);
        };

        const yourTeamTotal = calculateTeamTotal(formData.yourTeam);
        const yourOpponentTotal = calculateTeamTotal(formData.yourOpponent);

        setTeamTotals({ yourTeam: yourTeamTotal, yourOpponent: yourOpponentTotal });

        if (yourTeamTotal > 23) {
            alert('Your team total exceeds 23!');
        }
        if (yourOpponentTotal > 23) {
            alert('Your opponent total exceeds 23!');
        }
    }, [formData]);

    const handleSelectChange = (team, index, event) => {
        if (team === 'yourTeam' || team === 'yourOpponent') {

            const calculateTeamPlayerTotal = (teamData) => {
                return teamData.reduce((sum, item, indx) => {
                    // Subtract the current quantity if index matches indx, then add the new value.
                    return sum + (indx === index ? 0 : Number(item.quantity || 0));
                }, 0);
            };

            const calculateTeamTotal = (teamData) => {
                return teamData.reduce((sum, item, indx) => {
                    // Subtract the current quantity if index matches indx, then add the new value.
                    return sum + (indx === index ? 0 : Number(item.skillLevel || 0) * Number(item.quantity || 0));
                }, 0);
            };

            var teamPlayerTotal = 0;
            var teamTotal = 0;
            if (team === 'yourTeam') {
                if (Number(event.target.value) > 0) {//not a forfeit
                    teamPlayerTotal = calculateTeamPlayerTotal(formData.yourTeam) + Number(event.target.value);
                    teamTotal = calculateTeamTotal(formData.yourTeam) + Number(formData.yourTeam[index].skillLevel) * Number(event.target.value);
                }
                if (teamPlayerTotal > 5) {
                    alert('Number of players on your team total exceeds 5!');
                }
                if (teamTotal > 23) {
                    alert('Your team total exceeds 23!');
                }
            } else if (team === 'yourOpponent') {
                if (Number(event.target.value) > 0) {//not a forfeit
                    teamPlayerTotal = calculateTeamPlayerTotal(formData.yourOpponent) + Number(event.target.value);
                    teamTotal = calculateTeamTotal(formData.yourOpponent) + Number(formData.yourOpponent[index].skillLevel) * Number(event.target.value);
                }
                if (teamPlayerTotal > 5) {
                    alert('Number of players of your opponent total exceeds 5!');
                }
                if (teamTotal > 23) {
                    alert('Your opponent total exceeds 23!');
                }
            }

            if (teamPlayerTotal <= 5 && teamTotal <= 23) {
                const updatedData = { quantity: event.target.value };
                onChange(updatedData, index, team);
            }
        } else {
            console.log(team + ' was NOT FOUND');
        }
    };

    return (
        <div className="grid-x b1-ccc">
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-eee">
                <div className="font-raleway font-standard font-weight-600">Your Team</div>
            </div>
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-fafafa">
                <div className="font-raleway font-standard font-weight-600">The Opponent</div>
            </div>
            <div className="large-3 medium-3 small-3 cell text-center p-5 bg-eee">
                <div className="font-raleway font-standard font-weight-600">Skill</div>
            </div>
            <div className="large-3 medium-3 small-3 cell text-center p-5 bg-eee">
                <div className="font-raleway font-standard font-weight-600">Quantity</div>
            </div>
            <div className="large-3 medium-3 small-3 cell text-center p-5 bg-fafafa">
                <div className="font-raleway font-standard font-weight-600">Skill</div>
            </div>
            <div className="large-3 medium-3 small-3 cell text-center p-5 bg-fafafa">
                <div className="font-raleway font-standard font-weight-600">Quantity</div>
            </div>

            {formData.yourTeam.map((item, index) => (
                <React.Fragment key={item.skillLevel}>
                    {/* Your Team Skill Level Column */}
                    <div className="large-3 medium-3 small-3 cell text-center pt-10 bg-eee">{item.skillLevel > 0 ? item.skillLevel : 'Forfeit'}</div>
                    <div className="large-3 medium-3 small-3 cell text-center bg-eee">
                        <select
                            value={item.quantity}
                            onChange={(event) => handleSelectChange('yourTeam', index, event)}
                            className="small width-50px"
                        >
                            <option key="0" value="0">0</option>
                            {Array.from({ length: Math.min(5, Math.floor(23 / item.skillLevel)) }, (_, j) => j + 1).map((num) => (
                                <option key={num} value={num}>{num}</option>
                            ))}
                        </select>
                    </div>

                    {/* Opponent Skill Level Column */}
                    <div className="large-3 medium-3 small-3 cell text-center pt-10 bg-fafafa">{item.skillLevel > 0 ? item.skillLevel : 'Forfeit'}</div>
                    <div className="large-3 medium-3 small-3 cell text-center bg-fafafa">
                        <select
                            value={formData.yourOpponent[index].quantity}
                            onChange={(event) => handleSelectChange('yourOpponent', index, event)}
                            className="small width-50px"
                        >
                            <option key="0" value="0">0</option>
                            {Array.from({ length: Math.min(5, Math.floor(23 / item.skillLevel)) }, (_, j) => j + 1).map((num) => (
                                <option key={num} value={num}>{num}</option>
                            ))}
                        </select>
                    </div>
                </React.Fragment>
            ))}

            {teamTotals.yourTeam <= 23 ? (
                <div className="large-6 medium-6 small-6 cell text-center p-10 bt1-ccc bg-eee">
                    <div className="font-raleway font-standard font-weight-600">Total = {teamTotals.yourTeam}</div>
                </div>
            ) : (
                <div className="large-6 medium-6 small-6 cell text-center p-10 bt1-ccc bg-red">
                    <div className="font-raleway font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                </div>
            )}
            {teamTotals.yourOpponent <= 23 ? (
                <div className="large-6 medium-6 small-6 cell text-center p-10 bt1-ccc bg-fafafa">
                    <div className="font-raleway font-standard font-weight-600">Total = {teamTotals.yourOpponent}</div>
                </div>
            ) : (
                <div className="large-6 medium-6 small-6 cell text-center p-10 bt1-ccc bg-red">
                    <div className="font-raleway font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                </div>
            )}
        </div>
    );
}

export default Feature1Step1;