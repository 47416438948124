import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import AuthUtility from '../frontend/auth/AuthUtility';

import $ from "jquery";
import swal from 'sweetalert';

function Feature2Step3({ formData, onChange }) {
    const [availablePlayers, setAvailablePlayers] = useState({ yourTeam: [], yourOpponent: [] });
    const [selectedMatch, setSelectedMatch] = useState({ yourTeamPlayer: null, yourOpponentPlayer: null });
    const [teamTotals, setTeamTotals] = useState({ yourTeam: 0, yourOpponent: 0 });
    const [message, setMessage] = useState('');
    const [round, setRound] = useState(0);
    //const roundUpdated = useRef(false);
    const [roundUpdated, setRoundUpdated] = useState(false);
    const [teamPutsUpNext, setTeamPutsUpNext] = useState('');
    //const teamPutsUpNextUpdated = useRef(false);
    const [teamPutsUpNextUpdated, setTeamPutsUpNextUpdated] = useState(false);

    const calculateTeamTotal = (formData) => {
        return formData.reduce((sum, item, index) => sum + Number(item.skillLevel || 0) * Number(item.quantity || 0), 0);
    };
    const getAvailablePlayers = (formData) => {
        const availablePlayers = { yourTeam: [], yourOpponent: [] };

        ['yourTeam', 'yourOpponent'].forEach((team) => {
            formData[team].forEach((playerLevel, index) => {
                if (playerLevel.quantity > 0) {
                    // Create player objects and remove `quantity` and `completeMatches`
                    const players = Array.from({ length: playerLevel.quantity }, (_, i) => {
                        const { quantity, completeMatches, ...rest } = playerLevel; // Destructure to exclude properties
                        return { ...rest, instance: i }; // Include the remaining properties plus `instance`
                    });
                    availablePlayers[team].push(...players); // Spread the players into the team array
                }
            });
        });

        return availablePlayers;
    };

    useEffect(() => {

        const yourTeamTotal = calculateTeamTotal(formData.yourTeam);
        const yourOpponentTotal = calculateTeamTotal(formData.yourOpponent);
        const availablePlayers = getAvailablePlayers(formData)

        setTeamTotals({ yourTeam: yourTeamTotal, yourOpponent: yourOpponentTotal });

        setAvailablePlayers({ yourTeam: availablePlayers.yourTeam, yourOpponent: availablePlayers.yourOpponent });

        if (yourTeamTotal > 23) {
            alert('Your Team total exceeds 23!');
        }
        if (yourOpponentTotal > 23) {
            alert('Your Opponent total exceeds 23!');
        }

        const newRound = formData.matchUps.length + 1; // Determine the round based on matchUps size
        setRound(newRound);
        setRoundUpdated(true); // Mark as updated

        if (formData.selectedTeam === 'yourTeam') {//round 1,3,5
            if (newRound % 2 === 0) {//even
                setTeamPutsUpNext('yourOpponentPutsUpNext');
            } else {
                setTeamPutsUpNext('yourTeamPutsUpNext');
            }
            setTeamPutsUpNextUpdated(true);
        } else if (formData.selectedTeam === 'yourOpponent') {
            if (newRound % 2 === 0) {//even
                setTeamPutsUpNext('yourTeamPutsUpNext');
            } else {
                setTeamPutsUpNext('yourOpponentPutsUpNext');
            }
            setTeamPutsUpNextUpdated(true);
        }

    }, [formData.yourTeam, formData.yourOpponent, formData.selectedTeam, formData.matchUps, selectedMatch]);

    useEffect(() => {

        if ((roundUpdated && round > 0) || teamPutsUpNextUpdated) {
            updateMessage();
        }

    }, [formData, round, roundUpdated, teamPutsUpNextUpdated]);
    /*
    const getAvailablePlayers = (team) => {
        return team.flatMap((player) => {
            if (player.quantity > 0) {
                return Array.from({ length: player.quantity }, (_, i) => ({ ...player, instance: i }));
            }
            return [];
        });
    };
    
    const getAvailablePlayers = (team, teamType) => {
        return team.flatMap((player) => {
            const { skillLevel, quantity } = player;

            // Generate all possible player instances up to the current quantity
            const instances = Array.from({ length: quantity || 0 }, (_, i) => ({
                ...player,
                instance: i,
            }));

            // Ensure instances in completed matches are preserved
            const matchedInstances = formData.matchUps
                .filter(
                    (match) =>
                        //match.teamType === teamType && match.skillLevel === skillLevel
                        match.isComplete && match.selectedMatch[team] && match.selectedMatch[team].skillLevel === skillLevel
                )
                .map((match) => ({
                    ...player,
                    //instance: match.instance,
                    instance: match.selectedMatch[team].instance,
                }));

            // Combine matched instances with the remaining pool of available instances
            const allInstances = [...new Set([...instances, ...matchedInstances])];

            return allInstances;
        });
    };
    */

    const isPlayerMatched = (team, skillLevel, instance) => {
        return formData.matchUps.some(
            (match) => match.isComplete && match[team] && match[team].skillLevel === skillLevel && match[team].instance === instance
        );
    };

    const handleSelect = (team, player) => {
        setSelectedMatch((prev) => ({
            ...prev,
            [team]: player,
        }));

        if (teamPutsUpNext === 'yourTeamPutsUpNext') {
            setTeamPutsUpNext('yourOpponentPutsUpNext');
            setTeamPutsUpNextUpdated(true);
        } else if (teamPutsUpNext === 'yourOpponentPutsUpNext') {
            setTeamPutsUpNext('yourTeamPutsUpNext');
            setTeamPutsUpNextUpdated(true);
        }
        //message will be updated through the useEffect
    };

    const handleClearMatch = () => {

        setSelectedMatch({ yourTeamPlayer: null, yourOpponentPlayer: null });

        //teamPutsUpNext and message will be updated through the useEffect
    };

    const handlePlusMinusChange = (team, player, event) => {
        const updatedData = { plusMinus: event.target.value };

        setAvailablePlayers((prevData) => ({
            ...prevData,
            [team.replace("Player", "")]: prevData[team.replace("Player", "")].map((item, i) =>
                item.skillLevel === player.skillLevel && item.instance === player.instance ? { ...item, ...updatedData } : item
            ),
        }));
    };

    const handleCompleteMatch = () => {
        if (selectedMatch.yourTeamPlayer && selectedMatch.yourOpponentPlayer) {

            //var yourTeamPlayer = { skillLevel: selectedMatch.yourTeamPlayer.skillLevel, plusMinus: selectedMatch.yourTeamPlayer.plusMinus, instance: selectedMatch.yourTeamPlayer.instance };
            //var yourOpponentPlayer = { skillLevel: selectedMatch.yourOpponentPlayer.skillLevel, plusMinus: selectedMatch.yourOpponentPlayer.plusMinus, instance: selectedMatch.yourOpponentPlayer.instance };

            const updatedFormData = {
                ...formData,
                matchUps: [
                    ...formData.matchUps,
                    { yourTeamPlayer: selectedMatch.yourTeamPlayer, yourOpponentPlayer: selectedMatch.yourOpponentPlayer, isComplete: true },
                ],
                yourTeam: formData.yourTeam.map((player) =>
                    player.skillLevel === selectedMatch.yourTeamPlayer.skillLevel
                        ? { ...player, completeMatches: Number(player.completeMatches + 1) }
                        : player
                ),
                yourOpponent: formData.yourOpponent.map((player) =>
                    player.skillLevel === selectedMatch.yourOpponentPlayer.skillLevel
                        ? { ...player, completeMatches: Number(player.completeMatches + 1) }
                        : player
                ),
            };
            onChange(updatedFormData);

            setSelectedMatch({ yourTeamPlayer: null, yourOpponentPlayer: null });
        }
    };

    function updateMessage() {

        if (availablePlayers.yourTeam.length > 0 && availablePlayers.yourOpponent.length > 0) {
            if (formData.selectedTeam === 'yourTeam' || formData.selectedTeam === 'yourOpponent') {
                if (round <= 5) {
                    if (selectedMatch.yourTeamPlayer && selectedMatch.yourOpponentPlayer) {
                        setMessage('Round ' + round + ' - When the match is over click the button below');
                        $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green bg-light-gold').addClass('txt-000 bg-light-blue');
                    } else if (teamPutsUpNext === 'yourTeamPutsUpNext') {
                        setMessage('Round ' + round + ' - Your team puts up the next player.');
                        $('#message').removeClass('txt-333 txt-000 bg-light-red bg-light-blue bg-light-gold').addClass('txt-fff bg-light-green');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                        $('#yourOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                    } else if (teamPutsUpNext === 'yourOpponentPutsUpNext') {
                        setMessage('Round ' + round + ' - Your opponent puts up the next player.');
                        $('#message').removeClass('txt-333 txt-fff bg-light-green bg-light-blue bg-light-gold').addClass('txt-000 bg-light-red');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                        $('#yourOpponentUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                    } else {
                        setMessage('Warning - Something went wrong. Needs debugging.');
                        $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green bg-light-blue').addClass('txt-000 bg-light-gold');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                        $('#yourOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                    }
                } else {
                    setMessage('All Matches Complete');
                    $('#message').removeClass('txt-333 txt-000 bg-light-red bg-light-green bg-light-blue').addClass('txt-fff bg-dark-blue');
                }
            } else {
                setMessage('Warning - Steps 2 needs to be filled out.');
                $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green bg-light-blue').addClass('txt-000 bg-light-gold');
                $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                $('#yourOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
            }
        } else {
            setMessage('Warning - Steps 1 needs to be filled out.');
            $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green bg-light-blue').addClass('txt-000 bg-light-gold');
            $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
            $('#yourOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
        }

        setRoundUpdated(false);
        setTeamPutsUpNextUpdated(false);
    }

    return (
        <div className="grid-x b1-ccc">
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-eee">
                <div className="font-raleway font-standard font-weight-600">Your Team</div>
            </div>
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-fafafa">
                <div className="font-raleway font-standard font-weight-600">The Opponent</div>
            </div>
            {AuthUtility.isSubscribed() ? (
                <>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-eee">
                        <div className="font-raleway font-standard font-weight-600">Skill</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-eee">
                        <div className="font-raleway font-standard font-weight-600">- / +</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-eee">
                        <div className="font-raleway font-standard font-weight-600">Player</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-fafafa">
                        <div className="font-raleway font-standard font-weight-600">Skill</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-fafafa">
                        <div className="font-raleway font-standard font-weight-600">- / +</div>
                    </div>
                    <div className="large-2 medium-2 small-2 cell text-center p-5 bg-fafafa">
                        <div className="font-raleway font-standard font-weight-600">Player</div>
                    </div>
                </>
            ) : (
                <>
                    <div className="large-3 medium-3 small-3 cell text-center p-5 bg-eee">
                        <div className="font-raleway font-standard font-weight-600">Skill</div>
                    </div>
                    <div className="large-3 medium-3 small-3 cell text-center p-5 bg-eee">
                        <div className="font-raleway font-standard font-weight-600">Player</div>
                    </div>
                    <div className="large-3 medium-3 small-3 cell text-center p-5 bg-fafafa">
                        <div className="font-raleway font-standard font-weight-600">Skill</div>
                    </div>
                    <div className="large-3 medium-3 small-3 cell text-center p-5 bg-fafafa">
                        <div className="font-raleway font-standard font-weight-600">Player</div>
                    </div>
                </>
            )
            }
            <div id="yourTeamUI" className="large-6 medium-6 small-6 cell text-center bb1-ccc bg-eee">
                <div className="grid-x">
                    {availablePlayers.yourTeam.map((player, index) => (
                        AuthUtility.isSubscribed() ? (
                            <React.Fragment key={`yourTeam-${player.skillLevel}-${player.instance}`}>
                                <div className="large-4 medium-4 small-4 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.plusMinus}</div>
                                <div className="large-4 medium-4 small-4 cell text-center pt-5">
                                    <select
                                        onChange={(event) => handlePlusMinusChange('yourTeamPlayer', player, event)}
                                        className="small width-35px"
                                    >
                                        <option key="n" value="n">n</option>
                                        <option key="+" value="+">+</option>
                                        <option key="-" value="-">-</option>
                                    </select>
                                </div>
                                <div className="large-4 medium-4 small-4 cell text-center player-checkbox">
                                    <input type="checkbox" name={"yourTeam_" + index} id={"yourTeam_" + index} value="1"
                                        checked={
                                            selectedMatch.yourTeamPlayer &&
                                            selectedMatch.yourTeamPlayer.skillLevel === player.skillLevel &&
                                            selectedMatch.yourTeamPlayer.instance === player.instance
                                        }
                                        onChange={() => handleSelect('yourTeamPlayer', player)}
                                        disabled={isPlayerMatched('yourTeamPlayer', player.skillLevel, player.instance)}
                                    />
                                    <label htmlFor={"yourTeam_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment key={`yourTeam-${player.skillLevel}-${player.instance}`}>
                                <div className="large-6 medium-6 small-6 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.plusMinus}</div>
                                <div className="large-6 medium-6 small-6 cell text-center player-checkbox">
                                    <input type="checkbox" name={"yourTeam_" + index} id={"yourTeam_" + index} value="1"
                                        checked={
                                            selectedMatch.yourTeamPlayer &&
                                            selectedMatch.yourTeamPlayer.skillLevel === player.skillLevel &&
                                            selectedMatch.yourTeamPlayer.instance === player.instance
                                        }
                                        onChange={() => handleSelect('yourTeamPlayer', player)}
                                        disabled={isPlayerMatched('yourTeamPlayer', player.skillLevel, player.instance)}
                                    />
                                    <label htmlFor={"yourTeam_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                                </div>
                            </React.Fragment>
                        )
                    ))}

                    {teamTotals.yourTeam <= 23 ? (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-eee">
                            <div className="font-raleway font-standard font-weight-600">Total = {teamTotals.yourTeam}</div>
                        </div>
                    ) : (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-red">
                            <div className="font-raleway font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                        </div>
                    )}
                </div>
            </div>
            <div id="yourOpponentUI" className="large-6 medium-6 small-6 cell text-center bb1-ccc bg-fafafa">
                <div className="grid-x">
                    {availablePlayers.yourOpponent.map((player, index) => (
                        AuthUtility.isSubscribed() ? (
                            <React.Fragment key={`yourOpponent-${player.skillLevel}-${player.instance}`}>
                                <div className="large-4 medium-4 small-4 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.plusMinus}</div>
                                <div className="large-4 medium-4 small-4 cell text-center pt-5">
                                    <select
                                        onChange={(event) => handlePlusMinusChange('yourOpponentPlayer', player, event)}
                                        className="small width-35px"
                                    >
                                        <option key="n" value="n">n</option>
                                        <option key="+" value="+">+</option>
                                        <option key="-" value="-">-</option>
                                    </select>
                                </div>
                                <div className="large-4 medium-4 small-4 cell text-center player-checkbox">
                                    <input type="checkbox" name={"yourOpponent_" + index} id={"yourOpponent_" + index} value="1"
                                        checked={
                                            selectedMatch.yourOpponentPlayer &&
                                            selectedMatch.yourOpponentPlayer.skillLevel === player.skillLevel &&
                                            selectedMatch.yourOpponentPlayer.instance === player.instance
                                        }
                                        onChange={() => handleSelect('yourOpponentPlayer', player)}
                                        disabled={isPlayerMatched('yourOpponentPlayer', player.skillLevel, player.instance)}
                                    />
                                    <label htmlFor={"yourOpponent_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                                </div>
                            </React.Fragment>
                        ) : (

                            <React.Fragment key={`yourOpponent-${player.skillLevel}-${player.instance}`}>
                                <div className="large-6 medium-6 small-6 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.plusMinus}</div>
                                <div className="large-6 medium-6 small-6 cell text-center player-checkbox">
                                    <input type="checkbox" name={"yourOpponent_" + index} id={"yourOpponent_" + index} value="1"
                                        checked={
                                            selectedMatch.yourOpponentPlayer &&
                                            selectedMatch.yourOpponentPlayer.skillLevel === player.skillLevel &&
                                            selectedMatch.yourOpponentPlayer.instance === player.instance
                                        }
                                        onChange={() => handleSelect('yourOpponentPlayer', player)}
                                        disabled={isPlayerMatched('yourOpponentPlayer', player.skillLevel, player.instance)}
                                    />
                                    <label htmlFor={"yourOpponent_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                                </div>
                            </React.Fragment>
                        )
                    ))}

                    {teamTotals.yourOpponent <= 23 ? (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-fafafa">
                            <div className="font-raleway font-standard font-weight-600">Total = {teamTotals.yourOpponent}</div>
                        </div>
                    ) : (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-red">
                            <div className="font-raleway font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="large-12 medium-12 small-12 cell text-center bg-fff">
                <div id="message" className="font-raleway font-standard font-weight-500 txt-333 p-10">{message}</div>
            </div>

            <div className="large-12 medium-12 small-12 cell text-center bb1-333x bg-fff ptb-10 plr-5">
                <Link onClick={handleClearMatch} disabled={!selectedMatch.yourTeamPlayer && !selectedMatch.yourOpponentPlayer} className="button auto">
                    Clear Match
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Link onClick={handleCompleteMatch} disabled={!selectedMatch.yourTeamPlayer || !selectedMatch.yourOpponentPlayer} className="button auto">
                    Match Complete
                </Link>
            </div>
        </div>
    );
}

export default Feature2Step3;