import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../../components/frontend/auth/AuthUtility';

import swal from 'sweetalert';

import home_icon from '../../assets/frontend/images/home_icon.png';
import adjust_icon from '../../assets/frontend/images/adjust_icon.png';
import gear_icon from '../../assets/frontend/images/gear_icon.png';
import search_icon from '../../assets/frontend/images/search_icon.png';
import schedule_icon from '../../assets/frontend/images/schedule_icon.png';
import account_icon from '../../assets/frontend/images/account_icon.png';

const OnCanvasNavigation = () => {

	//logout should be its own component
	const navHistory = useNavigate();

	// using hooks
	const [isLoading, setIsLoading] = useState(false);

	const logoutSubmit = (event) => {
		event.preventDefault();

		setIsLoading(true);

		const fetchData = async () => {
			try {
				const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ backend: 'phpLaravel', token: localStorage.getItem('auth_token') });
				await getBearerToken();
				const response = await makeRequestWithHeaders('get', '/api/logout', null, {});

				setApiData(response);
			} catch (error) {
				handleApiError(error);
			}
		};

		fetchData();
	}
	function setApiData(response) {

		if (response && response.data) {
			if (response.data.status === 200) {//success

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();

				//redirect to home page
				navHistory('/');
			} else if (response.data.status === 401) {//user was not logged in

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();

				swal("Warning", response.data.message, "warning");

				navHistory('/login');
			} else {
				console.log('Error 40X: api call failed');

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();

				navHistory('/login');
			}
		}

		setIsLoading(false);
	}
	function handleApiError(error) {
		//csrf-cookie is outdated
		console.log('logoutSubmit error: ', error + ' csrf-cookie is outdated');

		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();

		setIsLoading(false);

		navHistory('/login');
	}


	return (
		<div className="sticky-bottom z-index-1010">
			<div className="sticky bg-eee bt1-ddd plr-20l-10sz ptb-10l-5s">

				<div className="panel large">
					<div className="horizontal-container center">
						<div className="element mlr-20l-10m-5s">
							<Link to="/home" className="button icon-sticky-bottom image-container no-underline">
								<div className="hide-for-small-only">
									<img src={home_icon} alt="add icon" width="35" className="pb-5" />
									<div className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">Home</div>
								</div>
								<div className="show-for-small-only">
									<div className="hide-for-520px">
										<img src={home_icon} alt="add icon" width="25" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Home</div>
									</div>
									<div className="show-for-520px">
										<img src={home_icon} alt="add icon" width="20" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Home</div>
									</div>
								</div>
							</Link>
						</div>
						<div className="element mlr-20l-10m-5s">
							<Link to="/search" className="button icon-sticky-bottom image-container no-underline">
								<div className="hide-for-small-only">
									<img src={search_icon} alt="search_icon" width="35" className="pb-5" />
									<div className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">Search</div>
								</div>
								<div className="show-for-small-only">
									<div className="hide-for-520px">
										<img src={search_icon} alt="search_icon" width="25" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Search</div>
									</div>
									<div className="show-for-520px">
										<img src={search_icon} alt="search_icon" width="20" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Srch</div>
									</div>
								</div>
							</Link>
						</div>
						<div className="element mlr-20l-10m-5s">
							<Link to="/member/feature_1" className="button icon-sticky-bottom image-container no-underline">
								<div className="hide-for-small-only">
									<img src={adjust_icon} alt="adjust_icon" width="35" className="pb-5" />
									<div className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">Ftr 1</div>
								</div>
								<div className="show-for-small-only">
									<div className="hide-for-520px">
										<img src={adjust_icon} alt="adjust_icon" width="25" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Ftr 1</div>
									</div>
									<div className="show-for-520px">
										<img src={adjust_icon} alt="adjust_icon" width="20" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Ftr 1</div>
									</div>
								</div>
							</Link>
						</div>
						<div className="element mlr-20l-10m-5s">
							<Link to="/member/feature_2" className="button icon-sticky-bottom image-container no-underline">
								<div className="hide-for-small-only">
									<img src={gear_icon} alt="gear_icon" width="35" className="pb-5" />
									<div className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">Ftr 2</div>
								</div>
								<div className="show-for-small-only">
									<div className="hide-for-520px">
										<img src={gear_icon} alt="gear_icon" width="25" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Ftr 2</div>
									</div>
									<div className="show-for-520px">
										<img src={gear_icon} alt="gear_icon" width="20" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Ftr 2</div>
									</div>
								</div>
							</Link>
						</div>
						<div className="element mlr-20l-10m-5s">
							<Link to="/member/feature_3" className="button icon-sticky-bottom image-container no-underline">
								<div className="hide-for-small-only">
									<img src={schedule_icon} alt="schedule_icon" width="35" className="pb-5" />
									<div className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">Ftr 3</div>
								</div>
								<div className="show-for-small-only">
									<div className="hide-for-520px">
										<img src={schedule_icon} alt="schedule_icon" width="25" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Ftr 3</div>
									</div>
									<div className="show-for-520px">
										<img src={schedule_icon} alt="schedule_icon" width="20" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Ftr 3</div>
									</div>
								</div>
							</Link>
						</div>
						<div className="element mlr-20l-10m-5s">
							<Link to="/member/account" className="button icon-sticky-bottom image-container no-underline">
								<div className="hide-for-small-only">
									<img src={account_icon} alt="account_icon" width="35" className="pb-5" />
									<div className="font-raleway font-small font-weight-600 letter-spacing-0px uppercase">Acct</div>
								</div>
								<div className="show-for-small-only">
									<div className="hide-for-520px">
										<img src={account_icon} alt="account_icon" width="25" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Acct</div>
									</div>
									<div className="show-for-520px">
										<img src={account_icon} alt="account_icon" width="20" className="pb-5" />
										<div className="font-raleway font-tiny font-weight-600 letter-spacing-0px uppercase">Acct</div>
									</div>
								</div>
							</Link>
						</div>
						
					</div>
				</div>

			</div>
		</div>
	);
}

export default OnCanvasNavigation;