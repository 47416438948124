// Step1.js
import React from 'react';

//function Feature1Step2({ checkedStates, handleCheckboxChange }) {
function Feature1Step2({ formData, onChange }) {

  const handleRadioChange = (event) => {
    const updatedData = { selectedTeam: event.target.value };
    onChange(updatedData, null, null); // No index or team needed for this change

    var cr=0;
  };

  return (
    <>
    <div className="grid-x">
      <div className="large-12 medium-12 small-12 cell text-center ptb-20 ">
        <div className="font-raleway font-large font-weight-600">Which  team put up first player?</div>
      </div>
      </div>
      <div className="grid-x b1-ccc">
      <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-eee">
        <div className="font-raleway font-standard font-weight-600">Your Team</div>
      </div>
      <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-fafafa">
        <div className="font-raleway font-standard font-weight-600">The Opponent</div>
      </div>
      <div className="large-6 medium-6 small-6 cell text-center p-20 bg-eee">
        <input
          type="radio"
          name="teamSelection"
          value="yourTeam"
          checked={formData.selectedTeam === 'yourTeam'}
          onChange={(event) => handleRadioChange(event)}
          className="custom-radio"
        />
      </div>
      <div className="large-6 medium-6 small-6 cell text-center p-20 bg-fafafa">
        <input
          type="radio"
          name="teamSelection"
          value="yourOpponent"
          checked={formData.selectedTeam === 'yourOpponent'}
          onChange={(event) => handleRadioChange(event)}
          className="custom-radio"
        />
      </div>
    </div>
    </>
  );
}

export default Feature1Step2;