import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Step1 from './Feature2Step1';
import Step2 from './Feature2Step2';
import Step3 from './Feature2Step3';

function Feature2() {
	const [formData, setFormData] = useState({
		yourTeam: [
			...Array.from({ length: 6 }, (_, i) => ({ skillLevel: i + 2, quantity: 0, plusMinus: '', completeMatches: 0 })), // Initializes 6 skill levels
			{ skillLevel: 0, quantity: 0, plusMinus: '', completeMatches: 0 }, // Adds forfeit entry
		],
		yourOpponent: [
			...Array.from({ length: 6 }, (_, i) => ({ skillLevel: i + 2, quantity: 0, plusMinus: '', completeMatches: 0 })), // Initializes 6 skill levels
			{ skillLevel: 0, quantity: 0, plusMinus: '', completeMatches: 0 }, // Adds forfeit entry
		],
		selectedTeam: '', // Stores which team is selected ("yourTeam" or "yourOpponent")
		matchUps: [], // This will be dynamically populated based on step 1 data
	});
	const [currentStep, setCurrentStep] = useState(0);

	const steps = [
		{ name: 'Step 1', component: Step1 },
		{ name: 'Step 2', component: Step2 },
		{ name: 'Step 3', component: Step3 },
		//{ name: 'Review', component: Review },
	];

	// This function will be responsible for both updating form data and matchups
	function handleFormChange(updatedData) {
		setFormData((prevFormData) => ({
			...prevFormData, // Keep the existing data
			...updatedData,  // Merge in updated data
		}));
	}

	const handleGoToStep = (stepIndex) => {
		setCurrentStep(stepIndex);
	};

	const CurrentComponent = steps[currentStep].component;

	return (
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">

			<div className="panel large pt-20l-10s">
				<div className="grid-x bb1-333 pb-5 hide-for-small-only">
					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase">Feature 2</div>
					</div>
					<div className="large-6 medium-6 small-6 cell text-right">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase">{steps[currentStep].name}</div>
					</div>
				</div>
				<div className="grid-x bb1-333 pb-5 show-for-small-only">
					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-raleway font-large font-weight-700 txt-333 uppercase">Feature 2</div>
					</div>
					<div className="large-6 medium-6 small-6 cell text-right">
						<div className="font-raleway font-large font-weight-700 txt-333 uppercase">{steps[currentStep].name}</div>
					</div>
				</div>
			</div>

			<div className="panel large">
				<CurrentComponent formData={formData} onChange={handleFormChange} />
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-center">

						<div className="pt-20l-10s">
							{steps.map((step, index) => (
								index === currentStep ? (
									<span key={index} className="font-raleway font-large font-weight-600 plr-15">
										{step.name}
									</span>
								) : (
									<Link key={index} onClick={() => handleGoToStep(index)} className="font-raleway font-large font-weight-500 plr-15">
										{step.name}
									</Link>
								)
							))}
						</div>
					</div>
				</div>
			</div>


		</div>
	);
}

export default Feature2;