import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import $ from "jquery";
import swal from 'sweetalert';

function Feature1Step3({ formData, onChange, teamData, selectedTeam }) {
    const [availablePlayers, setAvailablePlayers] = useState({ yourTeam: [], yourOpponent: [] });
    const [selectedMatch, setSelectedMatch] = useState({ yourTeamPlayer: null, yourOpponentPlayer: null });
    const [teamTotals, setTeamTotals] = useState({ yourTeam: 0, yourOpponent: 0 });
    const [message, setMessage] = useState('');
    const [round, setRound] = useState(1);

    useEffect(() => {
        const calculateTeamTotal = (teamData) => {
            return teamData.reduce((sum, item, index) => sum + Number(item.skillLevel || 0) * Number(item.quantity || 0), 0);
        };

        const yourTeamTotal = calculateTeamTotal(teamData.yourTeam);
        const yourOpponentTotal = calculateTeamTotal(teamData.yourOpponent);

        setTeamTotals({ yourTeam: yourTeamTotal, yourOpponent: yourOpponentTotal });

        setAvailablePlayers({ yourTeam: getAvailablePlayers(teamData.yourTeam), yourOpponent: getAvailablePlayers(teamData.yourOpponent) });

        if (yourTeamTotal > 23) {
            alert('Your team total exceeds 23!');
        }
        if (yourOpponentTotal > 23) {
            alert('Your opponent total exceeds 23!');
        }

        const newRound = formData.matchUps.length + 1; // Determine the round based on matchUps size
        setRound(newRound);

        var team = null;
        if (selectedTeam === 'yourTeam') {
            if (newRound === 1) {
                setMessage('Round 1 - Your team puts up the first player.');
            } else {
                if (newRound % 2 === 0) {//even
                    team = 'yourTeamPlayerDummy';
                } else {
                    team = 'yourOpponentPlayerDummy';
                }
                updateMessage(team, newRound);
            }
        } else if (selectedTeam === 'yourOpponent') {
            if (newRound === 1) {
                setMessage('Round 1 - Your opponent puts up the first player.');
            } else {
                if (newRound % 2 === 0) {//even
                    team = 'yourOpponentPlayerDummy';
                } else {
                    team = 'yourTeamPlayerDummy';
                }
                updateMessage(team, newRound);
            }
        }

    }, [formData, teamData, selectedTeam]);

    useEffect(() => {
        if (round === 1) {
            if (selectedTeam === 'yourTeam') {
                $('#message').removeClass('txt-333 txt-000').addClass('txt-fff bg-light-green');
                $('#yourOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
            } else if (selectedTeam === 'yourOpponent') {
                $('#message').removeClass('txt-333 txt-fff').addClass('txt-000 bg-light-red');
                $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interactio

            }
        }
    }, [availablePlayers, round, selectedTeam]);

    const getAvailablePlayers = (team) => {
        return team.flatMap((player) => {
            if (player.quantity > 0) {
                return Array.from({ length: player.quantity }, (_, i) => ({ ...player, instance: i }));
            }
            return [];
        });
    };

    const isPlayerMatched = (team, skillLevel, instance) => {
        var cr = 0;
        console.log('isPlayerMatched formData: ', formData);

        return formData.matchUps.some(
            (match) => match.isComplete && match.selectedMatch[team] && match.selectedMatch[team].skillLevel === skillLevel && match.selectedMatch[team].instance === instance
        );
    };

    const handleSelect = (team, player) => {
        setSelectedMatch((prev) => ({
            ...prev,
            [team]: player,
        }));

        updateMessage(team);

    };

    const handleMinusPlusChange = (team, player, event) => {
        const updatedData = { minusPlus: event.target.value };

        setAvailablePlayers((prevData) => ({
            ...prevData,
            [team.replace("Player", "")]: prevData[team.replace("Player", "")].map((item, i) =>
                item.skillLevel === player.skillLevel && item.instance === player.instance ? { ...item, ...updatedData } : item
            ),
        }));
    };

    const handleCompleteMatch = () => {
        if (selectedMatch.yourTeamPlayer && selectedMatch.yourOpponentPlayer) {
            const updatedData = { selectedMatch: selectedMatch, isComplete: true };
            onChange(updatedData, null, null);

            setSelectedMatch({ yourTeamPlayer: null, yourOpponentPlayer: null });
        }
    };

    function updateMessage(team = null, newRound = null) {

        var isEvenRound = null;
        var roundLocal = 0;
        if (newRound) {
            roundLocal = newRound;
            isEvenRound = newRound % 2 === 0; // Example: check if the round is even
        } else {
            roundLocal = round;
            isEvenRound = round % 2 === 0; // Example: check if the round is even
        }

        if (roundLocal <= 5) {
            if (selectedTeam === 'yourTeam') {
                if (!isEvenRound) {
                    if (team && team === 'yourOpponentPlayerDummy') {
                        setMessage('Round ' + roundLocal + ' - Your team puts up the next player.');
                        $('#message').removeClass('txt-333 txt-000 bg-light-red bg-light-blue').addClass('txt-fff bg-light-green');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                        $('#yourOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                    } else if (team && team === 'yourTeamPlayer') {
                        setMessage('Round ' + roundLocal + ' - Your opponent puts up the next player.');
                        $('#message').removeClass('txt-333 txt-fff bg-light-green bg-light-blue').addClass('txt-000 bg-light-red');
                        $('#yourOpponentUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                    } else {
                        setMessage('Round ' + roundLocal + ' - When the match is over click the button below');
                        $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green').addClass('txt-000 bg-light-blue');
                    }
                } else {
                    if (team && team === 'yourTeamPlayerDummy') {
                        setMessage('Round ' + roundLocal + ' - Your opponent puts up the next player.');
                        $('#message').removeClass('txt-333 txt-fff bg-light-green bg-light-blue').addClass('txt-000 bg-light-red');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                        $('#yourOpponentUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                    } else if (team && team === 'yourOpponentPlayer') {
                        setMessage('Round ' + roundLocal + ' - Your team puts up the next player.');
                        $('#message').removeClass('txt-333 txt-000 bg-light-red bg-light-blue').addClass('txt-fff bg-light-green');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                    } else {
                        setMessage('Round ' + roundLocal + ' - When the match is over click the button below');
                        $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green').addClass('txt-000 bg-light-blue');
                    }
                }
            } else if (selectedTeam === 'yourOpponent') {
                if (isEvenRound) {
                    if (team && team === 'yourOpponentPlayerDummy') {
                        setMessage('Round ' + roundLocal + ' - Your team puts up the next player.');
                        $('#message').removeClass('txt-333 txt-000 bg-light-red bg-light-blue').addClass('txt-fff bg-light-green');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                        $('#yourOpponentUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                    } else if (team && team === 'yourTeamPlayer') {
                        setMessage('Round ' + roundLocal + ' - Your opponent puts up the next player.');
                        $('#message').removeClass('txt-333 txt-fff bg-light-green bg-light-blue').addClass('txt-000 bg-light-red');
                        $('#yourOpponentUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                    } else {
                        setMessage('Round ' + roundLocal + ' - When the match is over click the button below');
                        $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green').addClass('txt-000 bg-light-blue');
                    }
                } else {
                    if (team && team === 'yourTeamPlayerDummy') {
                        setMessage('Round ' + roundLocal + ' - Your opponent puts up the next player.');
                        $('#message').removeClass('txt-333 txt-fff bg-light-green bg-light-blue').addClass('txt-000 bg-light-red');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'none'); // Disable interaction
                        $('#yourOpponentUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                    } else if (team && team === 'yourOpponentPlayer') {
                        setMessage('Round ' + roundLocal + ' - Your team puts up the next player.');
                        $('#message').removeClass('txt-333 txt-000 bg-light-red bg-light-blue').addClass('txt-fff bg-light-green');
                        $('#yourTeamUI .player-checkbox').css('pointer-events', 'auto'); // Re-enable interaction
                    } else {
                        setMessage('Round ' + roundLocal + ' - When the match is over click the button below');
                        $('#message').removeClass('txt-333 txt-fff bg-light-red bg-light-green').addClass('txt-000 bg-light-blue');
                    }
                }
            }
        } else {
            setMessage('All Matches Complete');
            $('#message').removeClass('txt-333 txt-000 bg-light-red bg-light-green bg-light-blue').addClass('txt-fff bg-dark-blue');
        }
    }

    return (
        <div className="grid-x b1-ccc">
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-eee">
                <div className="font-raleway font-standard font-weight-600">Your Team</div>
            </div>
            <div className="large-6 medium-6 small-6 cell text-center p-10 bb1-ccc bg-fafafa">
                <div className="font-raleway font-standard font-weight-600">The Opponent</div>
            </div>
            <div className="large-2 medium-2 small-2 cell text-center p-5 bg-eee">
                <div className="font-raleway font-standard font-weight-600">Skill</div>
            </div>
            <div className="large-2 medium-2 small-2 cell text-center p-5 bg-eee">
                <div className="font-raleway font-standard font-weight-600">- / +</div>
            </div>
            <div className="large-2 medium-2 small-2 cell text-center p-5 bg-eee">
                <div className="font-raleway font-standard font-weight-600">Player</div>
            </div>
            <div className="large-2 medium-2 small-2 cell text-center p-5 bg-fafafa">
                <div className="font-raleway font-standard font-weight-600">Skill</div>
            </div>
            <div className="large-2 medium-2 small-2 cell text-center p-5 bg-fafafa">
                <div className="font-raleway font-standard font-weight-600">- / +</div>
            </div>
            <div className="large-2 medium-2 small-2 cell text-center p-5 bg-fafafa">
                <div className="font-raleway font-standard font-weight-600">Plaer</div>
            </div>

            <div id="yourTeamUI" className="large-6 medium-6 small-6 cell text-center bb1-ccc bg-eee">
                <div className="grid-x">
                    {availablePlayers.yourTeam.map((player, index) => (
                        <React.Fragment key={`yourTeam-${player.skillLevel}-${player.instance}`}>
                            <div className="large-4 medium-4 small-4 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.minusPlus}</div>
                            <div className="large-4 medium-4 small-4 cell text-center pt-5">
                                <select
                                    onChange={(event) => handleMinusPlusChange('yourTeamPlayer', player, event)}
                                    className="small width-35px"
                                >
                                    <option key="n" value="n">n</option>
                                    <option key="-" value="-">-</option>
                                    <option key="+" value="+">+</option>
                                </select>
                            </div>
                            <div className="large-4 medium-4 small-4 cell text-center player-checkbox">
                                <input type="checkbox" name={"yourTeam_" + index} id={"yourTeam_" + index} value="1"
                                    checked={
                                        selectedMatch.yourTeamPlayer &&
                                        selectedMatch.yourTeamPlayer.skillLevel === player.skillLevel &&
                                        selectedMatch.yourTeamPlayer.instance === player.instance
                                    }
                                    onChange={() => handleSelect('yourTeamPlayer', player)}
                                    disabled={isPlayerMatched('yourTeamPlayer', player.skillLevel, player.instance)}
                                />
                                <label htmlFor={"yourTeam_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                            </div>
                        </React.Fragment>
                    ))}

                    {teamTotals.yourTeam <= 23 ? (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-eee">
                            <div className="font-raleway font-standard font-weight-600">Total = {teamTotals.yourTeam}</div>
                        </div>
                    ) : (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-red">
                            <div className="font-raleway font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                        </div>
                    )}
                </div>
            </div>
            <div id="yourOpponentUI" className="large-6 medium-6 small-6 cell text-center bb1-ccc bg-fafafa">
                <div className="grid-x">
                    {availablePlayers.yourOpponent.map((player, index) => (
                        <React.Fragment key={`yourOpponent-${player.skillLevel}-${player.instance}`}>
                            <div className="large-4 medium-4 small-4 cell text-center pt-15">{player.skillLevel > 0 ? player.skillLevel : 'Forfeit'}{player.minusPlus}</div>
                            <div className="large-4 medium-4 small-4 cell text-center pt-5">
                                <select
                                    onChange={(event) => handleMinusPlusChange('yourOpponentPlayer', player, event)}
                                    className="small width-35px"
                                >
                                    <option key="n" value="n">n</option>
                                    <option key="-" value="-">-</option>
                                    <option key="+" value="+">+</option>
                                </select>
                            </div>
                            <div className="large-4 medium-4 small-4 cell text-center player-checkbox">
                                <input type="checkbox" name={"yourOpponent_" + index} id={"yourOpponent_" + index} value="1"
                                    checked={
                                        selectedMatch.yourOpponentPlayer &&
                                        selectedMatch.yourOpponentPlayer.skillLevel === player.skillLevel &&
                                        selectedMatch.yourOpponentPlayer.instance === player.instance
                                    }
                                    onChange={() => handleSelect('yourOpponentPlayer', player)}
                                    disabled={isPlayerMatched('yourOpponentPlayer', player.skillLevel, player.instance)}
                                />
                                <label htmlFor={"yourOpponent_" + index} className="checkbox-label"><span className="checkbox"></span><span className="message"></span></label>
                            </div>
                        </React.Fragment>
                    ))}

                    {teamTotals.yourOpponent <= 23 ? (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-fafafa">
                            <div className="font-raleway font-standard font-weight-600">Total = {teamTotals.yourOpponent}</div>
                        </div>
                    ) : (
                        <div className="large-12 medium-12 small-12 cell text-center mt-5 p-10 bt1-ccc bg-red">
                            <div className="font-raleway font-standard font-weight-600 txt-fff">Total = {teamTotals.yourTeam}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="large-12 medium-12 small-12 cell text-center bg-fff">
                <div id="message" className="font-raleway font-standard font-weight-500 txt-333 p-10">{message}</div>
            </div>

            <div className="large-12 medium-12 small-12 cell text-center bb1-333x bg-fff p-10">
                <Link onClick={handleCompleteMatch} disabled={!selectedMatch.yourTeamPlayer || !selectedMatch.yourOpponentPlayer} className="button auto">
                    Match Complete
                </Link>
            </div>
        </div>
    );
}

export default Feature1Step3;